import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

function ProtectedRoutes({ children }) {
  const userId = useSelector((state) => state.auth.userId);
  const history = useHistory();
  if (!userId) {
    alert('You must log in to perform this action!');
    history.push('/login');
  }
  return children;
}

export default ProtectedRoutes;
