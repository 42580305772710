import React, { memo, useState } from 'react';
import {
  Box,
  Typography,
  Stack,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import EditRule from './EditRule';
import { removeRule } from './strategySlice';

function Rule({ ruleId }) {
  const {
    ruleName,
    actionType,
    assetName,
    triggerType,
    triggerSymbol,
    triggerValue,
    volumeType,
    triggerVolume,
  } = useSelector((state) => state.strategy.rules.find((r) => r.ruleId === ruleId));

  const [editMode, setEditMode] = useState(false);

  const dispatch = useDispatch();
  const handleRemoveRule = () => {
    dispatch(removeRule({ ruleId }));
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  if (editMode) {
    return <EditRule ruleId={ruleId} toggleEditMode={toggleEditMode} />;
  }
  return (
    <Box
      sx={{
        borderRadius: '10px',
        border: '1px solid lightgrey',
        margin: (theme) => theme.spacing(5),
        padding: (theme) => theme.spacing(3),
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          component="div"
          variant="h2"
          sx={{
            fontSize: (theme) => ({
              xs: theme.spacing(1.8),
              md: theme.spacing(2.5),
            }),
            fontWeight: 'bold',
            mt: '5px',
            mr: '15px',
          }}
        >
          {ruleName}
        </Typography>
        <Box>
          <Button
            variant="contained"
            onClick={toggleEditMode}
            sx={{
              boxShadow: 0,
              borderRadius: '8px',
              fontSize: (theme) => theme.spacing(2),
              textTransform: 'none',
              padding: (theme) => theme.spacing(1, 1.5),
              ml: '15px',
              height: '100',
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            onClick={handleRemoveRule}
            sx={{
              bgcolor: 'alert.main',
              boxShadow: 0,
              borderRadius: '8px',
              fontSize: (theme) => theme.spacing(2),
              textTransform: 'none',
              padding: (theme) => theme.spacing(1, 1.5),
              ml: '10px',
              height: '100',
            }}
          >
            Del
          </Button>
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="left" gap={1} pt={3}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell align="left">Asset</TableCell>
                <TableCell align="left">
                  Trigger (
                  {triggerType}
                  )
                </TableCell>
                <TableCell align="left">
                  Volume (
                  {volumeType}
                  )
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={ruleId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {actionType}
                </TableCell>
                <TableCell align="left">{assetName}</TableCell>
                <TableCell align="left">{`${triggerSymbol} ${triggerValue}`}</TableCell>
                <TableCell align="left">{`${triggerVolume}`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  );
}

export default memo(Rule);
