import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addRule } from './strategySlice';
import RuleForm from './RuleForm';

function NewRule({ toggleAddNewRuleMode, orderStatus }) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    ruleName: '',
    actionType: '',
    assetName: '',
    triggerType: '',
    triggerSymbol: '',
    triggerValue: 0,
    volumeType: '',
    triggerVolume: 0,
  });

  const isValidForm = () => {
    for (const att in state) {
      if (Object.prototype.hasOwnProperty.call(state, att) && !state[att]) {
        return false;
      }
    }
    return true;
  };

  const handleCancelRule = () => {
    toggleAddNewRuleMode();
  };

  const handleAddRule = () => {
    if (isValidForm()) {
      dispatch(addRule({ ...state, orderStatus }));
      toggleAddNewRuleMode();
    }
  };

  return (
    <RuleForm
      state={state}
      setState={setState}
      handleDoneButton={handleAddRule}
      handleCancelButton={handleCancelRule}
    />
  );
}

export default memo(NewRule);
