import { React, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Stack,
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
  styled,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLoading,
  selectUserStats,
  selectStrategies,
  postDeleteStrategy,
  fetchUserData,
  postUpdateIsDeployed,
  deleteStrategy,
  isDeployed,
} from './homeSlice';
import Spinner from '../../app/appState/Spinner';

const BorderBox = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  margin: theme.spacing(5),
  padding: theme.spacing(3),
}));

const UserStatPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const PillPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: theme.spacing(5),
  direction: 'row',
  borderRadius: 30,
  elevation: 50,
  color: theme.palette.text.secondary,
}));

function Home() {
  const userStats = useSelector(selectUserStats);
  const strategies = useSelector(selectStrategies);
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchUserData());
  }, []);

  const handleIsDeployed = (item) => {
    dispatch(postUpdateIsDeployed({ strategyId: item.strategyId }));
    dispatch(isDeployed({ ...item }));
  };

  const handleDeleteStrategy = (item) => {
    dispatch(postDeleteStrategy({ strategyId: item.strategyId }));
    dispatch(deleteStrategy({ ...item }));
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <Container maxWidth="lg">
      <BorderBox>
        <Grid container spacing={2}>
          {userStats.map((item) => (
            <Grid key={item.componentName} item xs={4}>
              <UserStatPaper>
                <Typography sx={{ fontSize: 16 }}>
                  {item.componentName}
                </Typography>
                <Typography sx={{ fontSize: 16 }}>
                  {item.componentValue}
                </Typography>
              </UserStatPaper>
            </Grid>
          ))}
        </Grid>
        <Paper sx={{ padding: 5 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ paddingBottom: 2 }}
          >
            <Typography variant="h3">Strategies</Typography>
            <Button
              variant="contained"
              onClick={() => history.push('/strategy')}
            >
              Add Strategy
            </Button>
          </Stack>
          <Paper spacing={2}>
            {strategies.map((item) => (
              <Stack
                key={item.strategyName}
                justifyContent="space-between"
                direction="row"
                spacing={2}
                sx={{ padding: 2, margin: 1, alignItems: 'center' }}
              >
                <Typography variant="h4" sx={{ width: '200px' }}>
                  {item.strategyName}
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Typography variant="h4">{item.numRules}</Typography>
                  <Typography variant="h4">Rules</Typography>
                </Stack>
                <FormGroup>
                  <FormControlLabel
                    sx={{ width: '120px' }}
                    control={(
                      <Switch
                        checked={item.isDeployed}
                        onChange={() => handleIsDeployed(item)}
                      />
                    )}
                    label={item.isDeployed ? 'Deployed' : 'Undeployed'}
                  />
                </FormGroup>
                <Button
                  variant="contained"
                  onClick={() => history.push(`/strategy/${item.strategyId}`)}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleDeleteStrategy(item)}
                >
                  Delete
                </Button>
              </Stack>
            ))}
          </Paper>
        </Paper>
      </BorderBox>
    </Container>
  );
}

export default Home;
