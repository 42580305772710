import {
  Button,
  Paper,
  Typography,
  TextField,
  Stack,
} from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { login, register } from './authenticationSlice';
import Spinner from '../../app/appState/Spinner';

export default function Login() {
  // when isLogin is true, show login page, when false show register page
  const history = useHistory();

  const paperStyle = {
    height: 550,
    width: 500,
    mt: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const dispatch = useDispatch();
  const { logError, loading, userId } = useSelector((state) => state.auth);

  if (userId) {
    history.push('/home');
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Must be valid email').required('Required'),
    password: Yup.string()
      .min(8, 'Minimum 8 characters')
      .max(14, 'Maximum 15 characters')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      if (login) {
        dispatch(
          login({
            email: values.email,
            password: values.password,
          }),
        );
      } else {
        dispatch(
          register({
            email: values.email,
            password: values.password,
          }),
        );
      }
      console.log(userId);
      if (userId) {
        history.push('/home');
      }
    },
  });

  let text = '';

  if (logError) {
    text = 'Incorrect email or password';
  }

  if (loading) {
    return <Spinner />;
  }
  return (
    <Stack
      sx={{ width: '100%', height: 'calc(100vh - 70px)' }}
      justifyContent="center"
      alignItems="center"
    >
      <Paper m={6} sx={paperStyle} elevation={3}>
        <Stack gap={2} direction="column" justify="center" alignItems="center">
          <Typography
            variant="h5"
            style={{
              fontSize: '35px',
              fontFamily: 'Segoe UI Emoji',
              fontWeight: 'bold',
              marginBottom: '20px',
              textAlign: 'center',
            }}
          >
            Welcome to HiProfit
          </Typography>

          <Typography
            style={{ fontSize: '25px', fontFamily: 'Segoe UI Emoji' }}
          >
            Login
          </Typography>

          <TextField
            sx={{
              width: '80%',
            }}
            label="Email"
            variant="standard"
            data-testid="loginEmail"
            id="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={Boolean(formik.errors.email)}
            helperText={formik.errors.email}
          />

          <TextField
            sx={{
              width: '80%',
            }}
            label="Password"
            type="password"
            data-testid="loginPassword"
            id="password"
            variant="standard"
            onChange={formik.handleChange}
            value={formik.values.password}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors.password)}
            helperText={formik.errors.password}
          />
          <Typography
            style={{
              fontSize: '15px',
              fontFamily: 'Segoe UI Emoji',
              color: 'red',
            }}
            data-testid="errorMessage"
          >
            {text}
          </Typography>
          <Stack justifyContent="center" alignItems="center">
            <Button
              variant="contained"
              onClick={() => formik.handleSubmit()}
              sx={{
                my: 2,
                padding: (theme) => theme.spacing(1, 3),
              }}
              data-testid="login"
            >
              Login
            </Button>
            <Button
              component="button"
              variant="body2"
              onClick={() => history.push('/register')}
              underline="hover"
              sx={{ width: '100%' }}
              data-testid="loginToggle"
            >
              Do not have an account? Sign Up
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
}
