import { createSlice, nanoid } from '@reduxjs/toolkit';
import { convertObjCamelCase } from '../../utils/formatter';
import { fetchAssets } from './api';

const initialState = {
  assets: [],
};

const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAssets.pending, (state) => {
      })
      .addCase(fetchAssets.fulfilled, (state, action) => {
        state.assets = action.payload.asset;
      })
      .addCase(fetchAssets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default assetSlice.reducer;
