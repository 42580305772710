import React, { memo } from 'react';
import {
  Box,
  Typography,
  Stack,
  Button,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useSelector } from 'react-redux';

function RuleForm({
  state, setState, handleCancelButton, handleDoneButton,
}) {
  const assets = useSelector((s) => s.asset.assets);
  const inputs = [
    {
      label: 'Action',
      name: 'actionType',
      type: 'select',
      items: [
        {
          label: 'BUY',
          value: 'Buy',
        },
        {
          label: 'SELL',
          value: 'Sell',
        },
      ],
    },
    {
      label: 'Asset',
      name: 'assetName',
      type: 'select',
      items: assets.map((asset) => ({
        label: asset,
        value: asset,
      })),
    },
    {
      label: 'Trigger',
      name: 'triggerType',
      type: 'select',
      items: [
        {
          label: 'Price',
          value: 'Price',
        },
      ],
    },
    {
      label: '',
      name: 'triggerSymbol',
      type: 'select',
      items: [
        {
          label: '=',
          value: '=',
        },
        {
          label: '<',
          value: '<',
        },
        {
          label: '<=',
          value: '<=',
        },
        {
          label: '>',
          value: '>',
        },
        {
          label: '>=',
          value: '>=',
        },
      ],
    },
    {
      label: 'Value',
      name: 'triggerValue',
      type: 'textfield',
    },
    {
      label: 'Volume',
      name: 'volumeType',
      type: 'select',
      items: [
        {
          label: 'Quantity',
          value: 'Qty',
        },
      ],
    },
    {
      label: 'Value',
      name: 'triggerVolume',
      type: 'textfield',
    },
  ];

  return (
    <Box
      sx={{
        borderRadius: '10px',
        border: '1px solid lightgrey',
        margin: (theme) => theme.spacing(5),
        padding: (theme) => theme.spacing(3),
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h2"
          sx={{
            fontSize: (theme) => ({
              xs: theme.spacing(1.8),
              md: theme.spacing(2.5),
            }),
            fontWeight: 'bold',
            mt: '5px',
            mr: '15px',
          }}
        >
          Rule name
        </Typography>
        <TextField
          value={state.ruleName}
          error={!state.ruleName}
          helperText={!state.ruleName && 'Required'}
          onChange={(e) => setState({
            ...state,
            ruleName: e.target.value,
          })}
          sx={{ flexGrow: 1 }}
          variant="outlined"
        />
        <Button
          variant="contained"
          onClick={handleCancelButton}
          sx={{
            boxShadow: 0,
            borderRadius: '8px',
            fontSize: (theme) => theme.spacing(2),
            textTransform: 'none',
            padding: (theme) => theme.spacing(1, 1.5),
            ml: '15px',
            height: '100',
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleDoneButton}
          sx={{
            boxShadow: 0,
            borderRadius: '8px',
            fontSize: (theme) => theme.spacing(2),
            textTransform: 'none',
            padding: (theme) => theme.spacing(1, 1.5),
            ml: '15px',
            height: '100',
          }}
        >
          Done
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="center" gap={1} pt={3}>
        {inputs.map(({
          label, name, type, items,
        }) => {
          if (type === 'select') {
            return (
              <FormControl style={{ minWidth: 100 }} key={name}>
                <InputLabel>{label}</InputLabel>
                <Select
                  id="action-input"
                  error={!state[name]}
                  value={state[name]}
                  label={label}
                  onChange={(e) => setState({
                    ...state,
                    [name]: e.target.value,
                  })}
                >
                  {items.map(({ label: itemLabel, value }) => (
                    <MenuItem key={name + itemLabel} value={value}>
                      {itemLabel}
                    </MenuItem>
                  ))}
                </Select>
                {!state[name] && <FormHelperText>Required</FormHelperText>}
              </FormControl>
            );
          }

          return (
            <TextField
              key={name}
              label={label}
              type="number"
              value={state[name]}
              error={!state[name]}
              helperText={!state[name] && 'Required'}
              sx={{
                width: '15%',
              }}
              onChange={(e) => setState({
                ...state,
                [name]: e.target.value,
              })}
            />
          );
        })}
      </Stack>
    </Box>
  );
}

export default memo(RuleForm);
