import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateRule } from './strategySlice';
import RuleForm from './RuleForm';

function EditRule({ ruleId, toggleEditMode }) {
  const {
    ruleName,
    actionType,
    assetName,
    triggerType,
    triggerSymbol,
    triggerValue,
    volumeType,
    triggerVolume,
  } = useSelector((state) => state.strategy.rules.find((r) => r.ruleId === ruleId));
  const dispatch = useDispatch();
  const [state, setState] = useState({
    ruleName,
    actionType,
    assetName,
    triggerType,
    triggerSymbol,
    triggerValue,
    volumeType,
    triggerVolume,
  });

  const isValidForm = () => {
    for (const att in state) {
      if (Object.prototype.hasOwnProperty.call(state, att) && !state[att]) {
        return false;
      }
    }
    return true;
  };

  const handleCancelChanges = () => {
    setState({
      ruleName,
      actionType,
      assetName,
      triggerType,
      triggerSymbol,
      triggerValue,
      volumeType,
      triggerVolume,
    });
    toggleEditMode();
  };

  const handleUpdateRule = () => {
    if (isValidForm()) {
      dispatch(updateRule({ ruleId, ...state }));
      toggleEditMode();
    }
  };

  return (
    <RuleForm
      state={state}
      setState={setState}
      handleDoneButton={handleUpdateRule}
      handleCancelButton={handleCancelChanges}
    />
  );
}

export default memo(EditRule);
