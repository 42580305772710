/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  userId: null,
  loading: false,
  error: false,
  userStats: [
    {
      componentName: 'My Current Net Worth',
      componentValue: '0',
    },
    {
      componentName: "Today's Profit",
      componentValue: '0',
    },
    {
      componentName: 'Total Strategies',
      componentValue: '0',
    },
    {
      componentName: 'Active Strategies',
      componentValue: '0',
    },
    {
      componentName: 'Win Trades Today',
      componentValue: '0',
    },
    {
      componentName: 'Loss Trades Today',
      componentValue: '0',
    },
  ],
  strategies: [],
};

export const fetchUserData = createAsyncThunk(
  'home/fetchUserData',
  async (payload, { getState }) => {
    const { userId } = getState().auth;
    const data = { user_id: userId };
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/get-home-data`, {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(data),
    });
    const resData = response.json();
    return resData;
  },
);

export const postDeleteStrategy = createAsyncThunk(
  'home/deleteStrategy',
  async ({ strategyId }, { getState }) => {
    const { userId } = getState().auth;
    const data = { strategy_id: strategyId, user_id: userId };
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/delete-strategy`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(data),
      });
      const resData = response.json();
      resData.strategyId = strategyId;
      return resData;
    } catch (error) {
      console.log(error);
    }
  },
);

export const postUpdateIsDeployed = createAsyncThunk(
  'home/deleteStrategy',
  async ({ strategyId }, { getState }) => {
    const { userId } = getState().auth;
    const data = { strategy_id: strategyId, user_id: userId };
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/switch-strategy`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(data),
      });
      const resData = response.json();
      resData.strategyId = strategyId;
      return resData;
    } catch (error) {
      console.log(error);
    }
  },
);

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    isDeployed(state, action) {
      const { strategyId } = action.payload;
      const existingStrategy = state.strategies.filter((s) => s.strategyId === strategyId);
      if (existingStrategy) {
        existingStrategy[0].isDeployed = !existingStrategy[0].isDeployed;
      }
    },
    deleteStrategy(state, action) {
      const { strategyId } = action.payload;
      state.strategies = state.strategies.filter((s) => s.strategyId !== strategyId);
    },
  },
  extraReducers: {
    [fetchUserData.pending]: (state) => {
      state.loading = true;
    },
    [fetchUserData.fulfilled]: (state, action) => {
      const { user_id, user_stats, strategies } = action.payload;
      state.userId = user_id;
      state.strategies = [];
      if (user_stats) {
        state.userStats = [];
        for (const key in user_stats) {
          state.userStats.push({
            componentName: user_stats[key].component_name,
            componentValue: user_stats[key].component_value,
          });
        }
      }
      if (strategies) {
        strategies.map((strategy) => (
          state.strategies.push({
            strategyId: strategy.strategy_id,
            strategyName: strategy.strategy_name,
            isDeployed: strategy.is_deployed,
            numRules: strategy.rule_count,
          })
        ));
      }
      state.loading = false;
    },
    [fetchUserData.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const selectUserStats = (state) => state.home.userStats;
export const selectStrategies = (state) => state.home.strategies;
export const selectLoading = (state) => state.home.loading;

export const {
  isDeployed, deleteStrategy,
} = homeSlice.actions;

export default homeSlice.reducer;
