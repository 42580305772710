import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAssets = createAsyncThunk(
  'asset/fetchAssets',
  async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/get-asset`,
      {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
      },
    );
    return response.json();
  },
);
