import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Home from './views/home/Home';
import Login from './views/authentication/Login';
import Navbar from './app/navbar/Navbar';
import Strategy from './views/strategy/Strategy';
import ProtectedRoutes from './app/ProtectedRoutes';
import Register from './views/authentication/Register';

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path="/login" component={() => <Login />} />
        <Route exact path="/register" component={() => <Register />} />
        <ProtectedRoutes>
          <Route exact path="/home" component={() => <Home />} />
          <Route exact path="/strategy" component={() => <Strategy />} />
          <Route
            exact
            path="/strategy/:strategyId"
            render={({ match }) => (
              <Strategy strategyId={match.params.strategyId} />
            )}
          />
        </ProtectedRoutes>
        <Redirect from="*" to="/login" />
      </Switch>
    </Router>
  );
}

export default App;
