import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Fade,
  IconButton,
  Container,
  Button,
  Box,
  Typography,
  ClickAwayListener,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import MenuDrawer from './MenuDrawer';
import MenuItems from './MenuItems';
import { signOut } from '../../views/authentication/authenticationSlice';

function Navbar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);
  const theme = useTheme();
  const expandMenu = useMediaQuery(theme.breakpoints.up('md'));
  const userId = useSelector((state) => state.auth.userId);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleSignOut = () => {
    dispatch(signOut());
    history.push('/login');
  };

  const handleLogIn = () => {
    history.push('/login');
  };

  useEffect(() => {
    if (expandMenu) {
      setOpenDrawer(false);
    }
  }, [expandMenu]);

  return (
    <ClickAwayListener onClickAway={handleCloseDrawer}>
      <AppBar position="static">
        <Container maxWidth="lg" sx={{ px: { xs: 2, md: 5 } }}>
          <Toolbar disableGutters>
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              HiProfit
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                flexBasis: 0,
                display: { xs: 'flex', md: 'none' },
              }}
            >
              <IconButton
                size="large"
                onClick={handleOpenDrawer}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Fade in={openDrawer}>
              <Box sx={{ zIndex: 2 }}>
                <MenuDrawer>
                  <MenuItems />
                </MenuDrawer>
              </Box>
            </Fade>
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                flexBasis: 1,
                display: { xs: 'flex', md: 'none' },
              }}
            >
              HiProfit
            </Typography>
            <Box
              sx={{ ml: 5, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
            >
              <Button
                onClick={() => history.push('/home')}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                <Typography
                  variant="h4"
                  color="white"
                  sx={{ fontSize: theme.spacing(2) }}
                >
                  Home
                </Typography>
              </Button>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={userId ? handleSignOut : handleLogIn}
                sx={{ color: 'white', borderColor: 'white', zIndex: 1 }}
              >
                {userId ? 'Sign Out' : 'Log in'}
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ClickAwayListener>
  );
}

export default Navbar;
