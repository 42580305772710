import React from 'react';
import { Box, List } from '@mui/material';

function MenuDrawer({ children }) {
  return (
    <Box sx={{
      py: 2,
      bgcolor: 'primary.main',
      width: '100%',
      height: '100px',
      position: 'fixed',
      top: 0,
      left: 0,
    }}
    >
      <List sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        lineHeight: 0.3,
      }}
      >
        {children}
      </List>
    </Box>
  );
}

export default MenuDrawer;
