import React from 'react';
import { ListItem, Typography, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

const navItem = {
  borderBottom: '1px solid transparent',
  paddingBottom: 0,
  paddingTop: 0,
  mb: 2,
  transition: 'border-bottom 0.1s linear',
  width: 'auto',
  '&:hover': {
    borderBottom: '1px solid white',
  },
};

function MenuItems() {
  const history = useHistory();
  return (
    <>
      <ListItem sx={navItem}>
        <Button onClick={() => history.push('/')}>
          <Typography
            component="div"
            variant="h4"
            color="white"
          >
            Home
          </Typography>
        </Button>
      </ListItem>
      <ListItem sx={navItem}>
        <Button onClick={() => history.push('/strategy')}>
          <Typography
            component="div"
            variant="h4"
            color="white"
          >
            Create Strategy
          </Typography>
        </Button>
      </ListItem>
    </>
  );
}

export default MenuItems;
