import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const login = createAsyncThunk('auth/login', async (payload) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND}/login`, {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({ ...payload }),
  });
  const result = await response.json();
  return result;
});

export const register = createAsyncThunk('auth/register', async (payload) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND}/register`, {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({ ...payload }),
  });
  const result = await response.json();
  return result;
});

const initialState = {
  userId: null,
  loading: false,
  logError: false,
  regError: false,
  userData: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut(state) {
      state.userData = {};
      state.userId = null;
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      if (action.payload.error === 'true') {
        state.logError = true;
      } else {
        state.userId = action.payload.user_id;
      }
      state.loading = false;
    },
    [login.rejected]: (state) => {
      state.loading = false;
      state.logError = true;
    },
    [register.pending]: (state) => {
      state.loading = true;
    },
    [register.fulfilled]: (state, action) => {
      console.log(action.payload);
      if (action.payload.error === 'true') {
        state.regError = true;
        state.userId = undefined;
        state.isLogin = false;
      } else {
        state.userId = action.payload.user_id;
        state.regError = false;
      }
      state.loading = false;
    },
    [register.rejected]: (state) => {
      state.loading = false;
      state.regError = true;
    },
  },
});

export const { signOut } = authSlice.actions;
export default authSlice.reducer;
