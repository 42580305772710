export const convertObjSnakeCase = (obj) => {
  if (typeof obj !== 'object') return obj;
  for (const oldName in obj) {
    const newName = oldName.replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`);

    if (newName !== oldName) {
      if (obj.hasOwnProperty(oldName)) {
        obj[newName] = obj[oldName];
        delete obj[oldName];
      }
    }

    if (typeof obj[newName] === 'object') {
      obj[newName] = convertObjSnakeCase(obj[newName]);
    }
  }
  return obj;
};

export const convertObjCamelCase = (obj) => {
  if (typeof obj !== 'object') return obj;
  for (const oldName in obj) {
    const newName = oldName.toLowerCase().replace(/([-_][a-z])/g, (group) => group
      .toUpperCase()
      .replace('-', '')
      .replace('_', ''));

    if (newName !== oldName) {
      if (obj.hasOwnProperty(oldName)) {
        obj[newName] = obj[oldName];
        delete obj[oldName];
      }
    }

    if (typeof obj[newName] === 'object') {
      obj[newName] = convertObjSnakeCase(obj[newName]);
    }
  }
  return obj;
};
