import { createAsyncThunk } from '@reduxjs/toolkit';
import { convertObjSnakeCase } from '../../utils/formatter';

export const fetchStrategy = createAsyncThunk(
  'strategy/fetchStrategy',
  async ({ strategyId }, { getState }) => {
    const { userId } = getState().auth;
    const data = { strategy_id: strategyId, user_id: userId };
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/get-strategy`,
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(data),
      },
    );
    return response.json();
  },
);

export const postStrategy = createAsyncThunk(
  'strategy/postStrategy',
  async (strategyData, { getState }) => {
    // SHAPING THE DATA
    const currRules = getState().strategy.rules;
    const { userId } = getState().auth;
    const rules = currRules.map((rule) => {
      const newRule = {
        ...rule,
      };
      delete newRule.ruleId;
      delete newRule.isNewRule;
      return convertObjSnakeCase(newRule);
    });

    const data = {
      user_id: userId.toString(),
      strategy_name: strategyData.strategyName,
      is_deployed: strategyData.isDeployed,
      rules,
    };

    // API request
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/add-strategy`,
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(data),
      },
    );
    return response.json();
  },
);

export const patchStrategy = createAsyncThunk(
  'strategy/patchStrategy',
  async (strategyData, { getState }) => {
    const currRules = getState().strategy.rules;
    const { userId } = getState().auth;
    const rules = currRules.map((rule) => {
      const newRule = {
        ...rule,
      };
      delete newRule.isNewRule;
      if (rule.isNewRule) {
        delete newRule.ruleId;
      }
      return convertObjSnakeCase(newRule);
    });

    const data = {
      strategy_id: strategyData.strategyId,
      strategy_name: strategyData.strategyName,
      is_deployed: strategyData.isDeployed,
      user_id: userId,
      rules,
    };

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/edit-strategy`,
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(data),
      },
    );
    return response.json();
  },
);
