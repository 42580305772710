import React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';

function Spinner() {
  return (
    <Backdrop
      open
      sx={{
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress
        sx={{
          color: 'primary.light',
        }}
        size="50px"
        thickness={5}
        value={50}
      />
    </Backdrop>
  );
}

export default Spinner;
