import { configureStore } from '@reduxjs/toolkit';
import strategyReducer from '../views/strategy/strategySlice';
import homeReducer from '../views/home/homeSlice';
import authenticationReducer from '../views/authentication/authenticationSlice';
import assetReducer from '../views/assets/assetSlice';

export default configureStore({
  reducer: {
    strategy: strategyReducer,
    home: homeReducer,
    auth: authenticationReducer,
    asset: assetReducer,
  },
});
