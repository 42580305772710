import { createSlice, nanoid } from '@reduxjs/toolkit';
import { fetchStrategy, postStrategy, patchStrategy } from './api';
import { convertObjCamelCase } from '../../utils/formatter';

const initialState = {
  loading: false,
  strategyId: '',
  strategyName: '',
  isDeployed: false,
  rules: [],
};

const strategySlice = createSlice({
  name: 'strategy',
  initialState,
  reducers: {
    addRule: {
      reducer(state, action) {
        state.rules.push({
          ...action.payload,
        });
      },
      prepare(data) {
        return {
          payload: {
            ruleId: nanoid(),
            isNewRule: true,
            ...data,
          },
        };
      },
    },
    clearState(state) {
      state.loading = false;
      state.strategyId = '';
      state.strategyName = '';
      state.isDeployed = false;
      state.rules = [];
    },
    changeStrategyName(state, action) {
      state.strategyName = action.payload.strategyName;
    },
    updateRule(state, action) {
      const {
        ruleId,
        ruleName,
        actionType,
        assetName,
        triggerType,
        triggerSymbol,
        triggerValue,
        volumeType,
        triggerVolume,
      } = action.payload;
      const existingRule = state.rules.find((r) => r.ruleId === ruleId);
      if (existingRule) {
        existingRule.ruleId = ruleId;
        existingRule.ruleName = ruleName;
        existingRule.actionType = actionType;
        existingRule.assetName = assetName;
        existingRule.triggerType = triggerType;
        existingRule.triggerSymbol = triggerSymbol;
        existingRule.triggerValue = triggerValue;
        existingRule.volumeType = volumeType;
        existingRule.triggerVolume = triggerVolume;
      }
    },
    removeRule(state, action) {
      const { ruleId } = action.payload;
      state.rules = state.rules.filter((r) => r.ruleId !== ruleId);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchStrategy.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStrategy.fulfilled, (state, action) => {
        const {
          is_deployed: isDeployed,
          strategy_id: strategyId,
          strategy_name: strategyName,
          rules,
        } = action.payload;
        state.loading = false;
        state.isDeployed = isDeployed;
        state.strategyId = strategyId;
        state.strategyName = strategyName;
        state.rules = rules ? rules.map((r) => convertObjCamelCase(r)) : [];
      })
      .addCase(fetchStrategy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(postStrategy.pending, (state) => {
        state.loading = true;
      })
      .addCase(postStrategy.fulfilled, (state, action) => {
        const { strategy_id: strategyId } = action.payload;
        state.loading = false;
        state.strategyId = strategyId;
      })
      .addCase(postStrategy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(patchStrategy.pending, (state) => {
        state.loading = true;
      })
      .addCase(patchStrategy.fulfilled, (state, action) => {
        const { strategy_id: strategyId } = action.payload;
        state.loading = false;
        state.strategyId = strategyId;
      })
      .addCase(patchStrategy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectStrategyData = (state) => ({
  strategyId: state.strategy.strategyId,
  strategyName: state.strategy.strategyName,
  isDeployed: state.strategy.isDeployed,
});
export const selectOpenRules = (state) => state.strategy.rules.filter((r) => r.orderStatus === 'OPEN');
export const selectClosedRules = (state) => state.strategy.rules.filter((r) => r.orderStatus === 'CLOSE');

export const {
  addRule, updateRule, removeRule, updateStrategy, clearState, changeStrategyName,
} = strategySlice.actions;
export default strategySlice.reducer;
