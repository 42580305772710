import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Stack,
  Button,
  TextField,
  Container,
  Tabs,
  ClickAwayListener,
  Tab,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/system';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { unwrapResult } from '@reduxjs/toolkit';
import Spinner from '../../app/appState/Spinner';
import {
  clearState,
  changeStrategyName,
  selectStrategyData,
  selectOpenRules,
  selectClosedRules,
} from './strategySlice';
import { fetchStrategy, postStrategy, patchStrategy } from './api';
import { fetchAssets } from '../assets/api';
import Rule from './Rule';
import NewRule from './NewRule';

const BorderBox = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  border: '1px solid lightgrey',
  margin: theme.spacing(5),
  padding: theme.spacing(3),
}));

const BigButton = styled(Button)(() => ({
  boxShadow: 'none',
  fontSize: '20px',
  textTransform: 'none',
}));

function TabPanel({ children, value, index }) {
  return (
    <Box hidden={value !== index}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function Strategy({ strategyId }) {
  const [currTab, setCurrTab] = useState(0);
  const [editMode, setEditMode] = useState(true);
  const [addRuleMode, setAddRuleMode] = useState(false);
  const dispatch = useDispatch();
  const openRules = useSelector(selectOpenRules);
  const closedRules = useSelector(selectClosedRules);
  const strategyData = useSelector(selectStrategyData);
  const { loading } = useSelector((state) => state.strategy);
  const history = useHistory();

  const [data, setData] = useState({
    ...strategyData,
  });

  const toggleAddNewRuleMode = () => {
    setAddRuleMode(!addRuleMode);
  };
  const handleAddRule = () => {
    toggleAddNewRuleMode();
  };
  const handleChangeStrategyName = () => {
    if (editMode) {
      dispatch(changeStrategyName({ strategyName: data.strategyName }));
    }
    setEditMode(!editMode);
  };

  const handleSaveStrategy = async () => {
    if (!data.strategyName) {
      return;
    }
    if (strategyId) {
      dispatch(patchStrategy({ ...data, strategyId }))
        .then(unwrapResult)
        .then((res) => {
          history.push(`/strategy/${res.strategy_id}`);
        });
    } else {
      dispatch(postStrategy({ ...data }))
        .then(unwrapResult)
        .then((res) => {
          history.push(`/strategy/${res.strategy_id}`);
        });
    }
  };

  useEffect(() => {
    setData({ ...strategyData });
  }, [strategyData]);

  useEffect(() => {
    if (!strategyId) {
      dispatch(clearState());
    } else {
      dispatch(fetchStrategy({ strategyId }));
    }
    dispatch(fetchAssets());
  }, []);

  if (loading) {
    return (
      <Spinner />
    );
  }
  return (
    <Container maxWidth="lg">
      <BorderBox>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h2"
            sx={{
              fontWeight: 'bold',
              mt: '5px',
            }}
          >
            {strategyId ? 'Edit Strategy' : 'Create Strategy'}
          </Typography>
          <Button
            variant="contained"
            onClick={() => history.push('/home')}
            sx={{
              bgcolor: 'alert.main',
              boxShadow: 0,
              borderRadius: '8px',
              fontSize: (theme) => theme.spacing(2),
              textTransform: 'none',
              padding: (theme) => theme.spacing(1, 1.5),
            }}
          >
            <KeyboardBackspaceIcon sx={{ mr: '3px' }} />
            Back
          </Button>
        </Stack>

        <BorderBox
          sx={{
            margin: 0,
            mt: (theme) => theme.spacing(5),
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {editMode ? (
              <>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: (theme) => ({
                      xs: theme.spacing(1.8),
                      md: theme.spacing(2.5),
                    }),
                    fontWeight: 'bold',
                    mt: '5px',
                    mr: '15px',
                  }}
                >
                  Strategy Name
                </Typography>
                <ClickAwayListener
                  onClickAway={() => data.strategyName && handleChangeStrategyName()}
                >
                  <TextField
                    sx={{ flexGrow: 1, mr: '15px' }}
                    id="strategy-name"
                    error={!data.strategyName}
                    helperText={!data.strategyName && 'Required'}
                    variant="outlined"
                    value={data.strategyName}
                    onChange={(e) => setData({ ...data, strategyName: e.target.value })}
                  />
                </ClickAwayListener>
              </>
            ) : (
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  mt: '5px',
                }}
              >
                {data.strategyName}
              </Typography>
            )}
            <Button
              variant="contained"
              onClick={() => data.strategyName && handleChangeStrategyName()}
            >
              {editMode ? 'Set Name' : 'Edit'}
            </Button>
          </Stack>
          <BorderBox
            sx={{
              margin: 0,
              mt: (theme) => theme.spacing(5),
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Tabs
                value={currTab}
                onChange={(event, newValue) => {
                  setCurrTab(newValue);
                }}
                aria-label="basic tabs example"
              >
                <Tab label="Open" />
                <Tab label="Closed" />
              </Tabs>
              <Button
                variant="contained"
                onClick={handleAddRule}
                sx={{
                  boxShadow: 0,
                  borderRadius: '8px',
                  fontSize: (theme) => theme.spacing(2),
                  textTransform: 'none',
                  padding: (theme) => theme.spacing(1, 1.5),
                }}
              >
                Add rule
              </Button>
            </Stack>
            <TabPanel value={currTab} index={0}>
              {openRules.map(({ ruleId }) => (
                <Rule key={ruleId} ruleId={ruleId} />
              ))}
              {addRuleMode && currTab === 0 && (
                <NewRule
                  toggleAddNewRuleMode={toggleAddNewRuleMode}
                  orderStatus="OPEN"
                />
              )}
            </TabPanel>
            <TabPanel value={currTab} index={1}>
              {closedRules.map(({ ruleId }) => (
                <Rule key={ruleId} ruleId={ruleId} />
              ))}
              {addRuleMode && currTab === 1 && (
                <NewRule
                  toggleAddNewRuleMode={toggleAddNewRuleMode}
                  orderStatus="CLOSE"
                />
              )}
            </TabPanel>
          </BorderBox>
          <Stack direction="row" justifyContent="right" gap={2} mt={3}>
            <BigButton
              variant="contained"
              onClick={() => {
                setData({
                  ...data,
                  isDeployed: !data.isDeployed,
                });
              }}
            >
              {data.isDeployed ? 'Undeploy' : 'Deploy'}
            </BigButton>
            <BigButton onClick={handleSaveStrategy} variant="contained">
              Save
            </BigButton>
          </Stack>
        </BorderBox>
      </BorderBox>
    </Container>
  );
}

export default Strategy;
